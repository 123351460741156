import { doPrivatePostRequest } from "./requests"

export const getOrders = () => {
    const payload = {
        'fn': 'getOrders',
        'params': {
            'st': 'GET-TABLE-DATA-BY-DATE',
            'sk': null,
            'sv': null,
            'sd': '2023-08-31',
            'ed': '2023-09-12'
        }
    }
    return doPrivatePostRequest(payload)
}

export const getTableOrdersByDate = (_dr) => {
    const payload = {
        'fn': 'getOrders',
        'params': {
            'st': 'BY-DATE',
            'sk': null,
            'sv': null,
            'sd': _dr[0],
            'ed': _dr[1]
        }
    }
    return doPrivatePostRequest(payload)
}

export const getTableOrdersByEmail = (_email) => {
    const payload = {
        'fn': 'getOrders',
        'params': {
            'st': 'BY-CLIENT-EMAIL',
            'sk': _email,
            'sv': null
        }
    }
    return doPrivatePostRequest(payload)
}

export const getTableOrdersByDateRange = (_dr) => {
    const payload = {
        'fn': 'getOrders',
        'params': {
            'st': 'GET-TABLE-DATA-BY-DATE',
            'sk': null,
            'sv': null,
            'sd': _dr[0],
            'ed': _dr[1]
        }
    }
    return doPrivatePostRequest(payload)
}

export const getTableOrdersQuotesByDateRange = (_dr) => {
    const payload = {
        'fn': 'getOrders',
        'params': {
            'st': 'GET-TABLE-DATA-BY-DATE-QUOTES',
            'sk': null,
            'sv': null,
            'sd': _dr[0],
            'ed': _dr[1]
        }
    }
    return doPrivatePostRequest(payload)
}

export const getSetTableOrdersByDateRange = () => {
    const payload = {
        'fn': 'getOrders',
        'params': {
            'st': 'BY-DATE',
            'sk': null,
            'sv': null,
            'sd': '2010-03-01',
            'ed': '2030-03-01'
        }
    }
    return doPrivatePostRequest(payload)
}

export const getOrderById = (_order_id) => {
    const payload = {
        'fn': 'getOrders',
        'params': {
            'st': 'DOC-ID',
            'sk': _order_id,
            'sv': null
        }
    }
    return doPrivatePostRequest(payload)
}

export const updateOrder = (_order) => {
    const payload = {
        'fn': 'updateOrder',
        'params': {
            'order': _order
        }
    }
    return doPrivatePostRequest(payload)
}

export const getClientOrders = (_client_id) => {
    const payload = {
        'fn': 'getOrders',
        'params': {
            'st': 'GET-TABLE-BY-CLIENT-ID',
            'sk': _client_id,
            'sv': null,
        }
    }
    return doPrivatePostRequest(payload)
}

export const getClientOrdersPaid = (_client_id) => {
    const payload = {
        'fn': 'getOrders',
        'params': {
            'st': 'BY-CLIENT-PAID',
            'sk': _client_id,
            'sv': true,
        }
    }
    return doPrivatePostRequest(payload)
}

export const getAffiliateOrders = (_affiliate_id) => {
    const payload = {
        'fn': 'getOrders',
        'params': {
            'st': 'BY-AFFILIATE-ID',
            'sk': _affiliate_id,
            'sv': null
        }
    }
    return doPrivatePostRequest(payload)
}

export const createInternalOrder = (_order_data) => {
    const payload = {
        'fn': 'createInternalOrder',
        'params': {'order': _order_data}
    }
    return doPrivatePostRequest(payload)
}

export const cancelOrder = (_order_id) => {
    const payload = {
        'fn': 'cancelOrder',
        'params': {'order_id': _order_id}
    }
    return doPrivatePostRequest(payload)
}

export const createOrder = (_order) => {
    const payload = {
        'fn': 'createOrder',
        'params': {
            'order': _order
        }
    }
    return doPrivatePostRequest(payload)
}

export const getQuotesByDate = (_sd, _ed) => {
    const payload = {
        'fn': 'createOrder',
        'params': {
            'start_date': _sd,
            'end_date': _ed
        }
    }
    return doPrivatePostRequest(payload)
}

export const runOrderPaymentNotifications = (_doc_id) => {
    const payload = {
        'fn': 'runOrderPaymentNotifications',
        'params': {
            'doc_id': _doc_id
        }
    }
    return doPrivatePostRequest(payload)
}